<template>
  <div>
    <FormController v-if="!grid_key" class="form" :submit="submit" data-form-type="other">
      <template #default>
        <p>
          You will need an API key to connect wit GRID data. You can access your API key in the GRIDs
          <a href="https://dashboard.grid.gg/my-organisation" target="_blank" rel="noopener noreferrer"
            >"My Organization"</a
          >
          section, viewing any of the accounts and navigating to the API Keys tab.
        </p>

        <p>
          Go to
          <a href="https://dashboard.grid.gg/my-organisation" target="_blank" rel="noopener noreferrer"
            >"My Organization"</a
          >
        </p>

        <b-form-group class="flex-fill" label="GRID API key" label-for="grid_key">
          <b-form-input
            id="grid_key"
            v-model.trim="$v.form.grid_key.$model"
            type="text"
            :class="{
              'is-valid': $v.form.grid_key.$model !== '',
              'is-invalid': $v.form.grid_key.$error,
            }"
          />
          <div class="text-danger" v-if="$v.form.grid_key.$error && !$v.form.grid_key.required">Field is required</div>
          <div class="text-danger" v-if="$v.form.grid_key.$error && !$v.form.grid_key.checkKey">
            API key is not valid.
          </div>
          <div class="text-secondary mt-3" v-if="$v.form.grid_key.$pending"><p>Checking API key...</p></div>
        </b-form-group>

        <b-button type="submit" variant="warning" :disabled="$v.form.$invalid"> Submit </b-button>
      </template>
    </FormController>

    <FormController v-if="grid_key" class="form" :submit="clear" data-form-type="other">
      <template #default>
        <p>
          Your GRID API key is <strong>{{ grid_key.substr(0, 3) }}...{{ grid_key.substr(-3) }}</strong>
        </p>
        <b-button type="submit" variant="danger"> Clear </b-button>
      </template>
    </FormController>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'

import { checkGridAccess } from '@/api/grid.js'
import { submitPersistentData } from '@/api/persistentData.js'
import FormController from '@/components/controllers/FormController.vue'

export default {
  name: 'SubmitGridKey',
  mixins: [validationMixin],
  components: { FormController },
  data() {
    return {
      form: {
        grid_key: '',
      },
      checkKeyTimeout: null,
    }
  },

  validations: {
    form: {
      grid_key: {
        required,
        async checkKey(gridApiKey) {
          if (!gridApiKey) {
            return false
          }

          return new Promise(resolve => {
            if (this.checkKeyTimeout) {
              clearTimeout(this.checkKeyTimeout)
            }
            this.checkKeyTimeout = setTimeout(async () => {
              resolve(
                await checkGridAccess({
                  apiKey: gridApiKey,
                })
              )
            }, 1000)
          })
        },
      },
    },
  },

  computed: {
    ...mapGetters({
      grid_key: 'auth/grid_key',
    }),
  },

  methods: {
    ...mapMutations({
      persistGridKey: 'auth/GRID_KEY',
    }),
    async clear() {
      await submitPersistentData({
        body: {
          grid_key: '',
        },
      })

      this.persistGridKey('')
      this.$emit('success')
    },
    async submit() {
      const { grid_key } = this.form

      await submitPersistentData({
        body: {
          grid_key,
        },
      })

      this.persistGridKey(grid_key)
      this.$emit('success')
    },
  },
}
</script>
