<template>
  <div>
    <SubmitGridKey @success="onSuccess" />
  </div>
</template>

<script>
import SubmitGridKey from '@/components/grid/SubmitGridKey.vue'

export default {
  name: 'RequestGridKeyPage',
  components: {
    SubmitGridKey,
  },
  props: {
    redirect: {
      type: String,
      default: null,
    },
  },
  methods: {
    async onSuccess() {
      await this.$router.push(this.redirect || '/')
    },
  },
}
</script>
